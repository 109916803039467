import React, { useEffect, useState } from "react";
import MultiDatePicker from "react-multi-date-picker";
import moment from "moment";
import "./goondemand.css";
import { useDispatch, useSelector } from "react-redux";
import Questions from "./questionsbox";
import ScheduledetailsModal from "../../../admin/ondemandupdates/scheduledetails";
import {
  addMediumToOnDemandQueue,
  addMediumToOnDemandQueueBackFill,
  bookOnDemandSession,
  checkMediumInOnDemandQueue,
  getMediumOndemandEvents,
  getZoomLinkOnDemand,
  removeMediumFromOnDemandQueue,
  removeMediumFromOnDemandQueueBackFill,
} from "../../../../services/medium";
import AlredyJoined from "./alreadyJoined";
import ConfirmOut from "./confirmOut";
import { el } from "date-fns/locale";
import Pusher from "pusher-js";
import ReminderPopUp from "./reminderPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import CountdownTimerReact from "./countdown";
import { getTimeImage } from "../../../../utils/utils";
import { setLoadingContent, setLoadingText } from "../../../../reducer/auth/auth";
import TimeEndedPopup from "./timeendedpopup";
import ForceBackHandler from "./forcebackhandler";

export default function BackFromMedium() {
  const { timezone, id } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const appId = searchParams.get('appId');
  const runTime = searchParams.get('time');
  const [loaderType, setloaderType] = useState("3");
  const [inputValue, setInputValue] = useState("");
  const [info, setInfo] = useState();
  const [Index, setIndex] = useState(0);
  const [eventsList, setEventList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [values, setValues] = useState([]);
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [timer, setTimer] = useState(null);
  const [activeEvent, setActiveEvent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [time, setTime] = useState(0);

  const [ondemandStatus, setOndamandStatus] = useState(false);
  const [counterStatus, setCounterStatus] = useState(false);
  const [shoHostStatus, setshoHostStatus] = useState(false);
  const [meeting_link, setmettingLink] = useState("");
  const [meetingLinkStatus, setmettingLinkStatus] = useState(false);
  const [appoinmentId, setAppoinmentId] = useState("");

  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 5); // Load next 8 items
  };
  const [message, setMessage] = useState("");
  let interval;
  const navigate = useNavigate();

  const day = (day) => moment(day).format("ddd"); // Short day name (e.g., 'Fri')
  const month = (month) => moment(month).format("MMM"); // Short month name (e.g., 'Sep')
  const date = (date) => moment(date).format("DD");
  const times = (time) => moment(time, "HH:mm").format("hh:mm A");

  useEffect(() => {
    if (localStorage.getItem('appoinmentId')) {
      navigate('/appointments')
    }
    // setTimeout(() => {
    //   const newUrl = new URL(window.location.href);
    //   newUrl.searchParams.delete("appId");
    //   newUrl.searchParams.delete("time");


    //   window.history.replaceState({}, document.title, newUrl.href);
    // }, 3000);

    addMediumQueue();

  }, [])
  const checkOldTime = (datee, timee) => {
    let status = false;
    // Define the target date and time using Moment.js
    const targetDate = moment(`${datee} ${timee}`, "YYYY-MM-DD hh:mm A"); // 6:00 PM is 18:00 in 24-hour format
    // Get the current date and time
    const currentDate = moment(
      moment().format("YYYY-MM-DD hh:mm A"),
      "YYYY-MM-DD hh:mm A"
    );

    // Check if the current time is past the target time
    if (currentDate.isSame(targetDate)) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  const runIntervalFunc = (event = []) => {
    interval = setInterval(() => {
      let list = eventsList.length > 0 ? eventsList : event;
      let newlist = list.filter((item) => {
        if (!checkOldTime(item.backupdate, item.end_time)) {
          return item;
        } else {
          return;
        }
      });
      let newEvent = newlist.map((item) => {
        // console.log(item.backupdate, item.time);
        let status = checkTime(item.backupdate, item.time, item.end_time);
        return {
          ...item,
          showReminder: status,
        }; // Example: Incrementing each value
      });
      // console.log(newEvent, "newEvent")
      setEventList(newEvent);
    }, 6000); // 60 seconds = 60000 ms
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  };

  const getClientProfileDetails = (item, index) => {
    setIndex(index);
    setInfo(item);
    window.loadModal2("#ScheduledetailsModal", "show");
  };
  const handleValueChange = (newValues) => {
    if (newValues) {
      setValues(newValues);
      let name = {};
      if (newValues.length === 2) {
        const startDate = newValues[0].format("YYYY-MM-DD");
        const endDate = newValues[1].format("YYYY-MM-DD");
        // name["created_at"] = `${startDate},${endDate}`; // Fix the assignment
        setFromTime(startDate);
        setToTime(endDate);

      }
      // if(newValues.length === 1){
      //   console.log( newValues[0].format("YYYY-MM-DD"))
      //   const startDate = newValues[0].format("YYYY-MM-DD");
      //   getEventList('', '','',startDate)
      // }
    }
  };
  const inputChanged = (e) => {
    let value = e.target.value;
    setInputValue(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (e.target.value.length > 2 && e.target.value.length === 0) {

      }
    }, 2000);

    setTimer(newTimer);
  };
  const checkTime = (date, time, end) => {
    const dateToCheck = moment(date); // Replace with the date you want to check
    const isToday = dateToCheck.isSame(moment(), "day");
    // console.log(isToday, date)

    if (isToday) {
      // Convert input to moment
      let currentTime = moment().format("YYYY-MM-DD HH:mm");
      let endtime = moment(`${date} ${end}`, "YYYY-MM-DD hh:mm A").format(
        "YYYY-MM-DD HH:mm"
      );

      const inputMoment = moment(
        `${date} ${time}`,
        "YYYY-MM-DD hh:mm A"
      ).format("YYYY-MM-DD HH:mm");
      const time1 = moment(currentTime, "YYYY-MM-DD HH:mm");
      const time2 = moment(inputMoment, "YYYY-MM-DD HH:mm");

      const time3 = moment(endtime, "YYYY-MM-DD HH:mm");
      const minutes = time3.diff(time1, "minutes");
      const minutesDifference = time1.diff(time2, "minutes");
      let status = minutesDifference >= 0 && minutes > 0;

      return status;
    } else {
      return isToday;
    }
    return;

    // Check if the difference is between 0 and 10 (inclusive) or exactly 0
  };
  const openReminderPopup = (info) => {
    localStorage.setItem("activeEvent", info.id);
    setActiveEvent(info);
    window.loadModal2("#ReminderPopUp", "show");
  };


  const addMediumQueue = (check) => {

    let body =
    {
      appointment_id: appId
    }

    addMediumToOnDemandQueueBackFill(body)
      .then((data) => {

        console.log(data.data)
        if (data.data.success == "Request completed successfully") {
          // let matched = localStorage.getItem("matched");
          // if (matched) {
          //   return;
          // }

          localStorage.setItem("ondemandLoader", "3");
          // setloaderType("3");
          setIsActive(true);
          checkTimes();
          // setTime(time + 1);
          // bookSession will start here
        }
      })
      .catch(() => { });
  };

  const removeMediumFromQueue = () => {
    let body =
    {
      appointment_id: appId
    }
    removeMediumFromOnDemandQueueBackFill(body)
      .then((data) => {
        setTimeLeft('');
       })
      .catch(() => { });
  };
  useEffect(() => {
    if (time > 0 && !ondemandStatus) {
      let status = checkOldTime(activeEvent.backupdate, activeEvent.end_time);
      if (status) {
        setOndamandStatus(true);
        removeMediumFromQueue();
        localStorage.setItem("ondemandLoader", "1");
        setloaderType("1");
        return;
      }

      // bookSession();
    }
  }, [time, ondemandStatus]);
  const bookSession = (event_id) => {
    let body = {
      event_id: activeEvent.id,
    };
    bookOnDemandSession(body)
      .then((data) => {
        if (!data.data.status) {
          setTime(time + 1);
        } else {
          setOndamandStatus(true);
          setTime(-3);
        }
        // console.log(data, "data");
      })
      .catch(() => { });
  };
  const startLoaderNow = (user_pereference) => {

    setTime(0);
    // addMediumQueue();
  };

  useEffect(() => {
    const handleBeforeUnload = (event, confirmExit) => {
      let ondemandLoader = localStorage.getItem("ondemandLoader");
      if (ondemandLoader == "3") {
        event.preventDefault();
        setIsActive(false);
        removeMediumFromQueue();
        event.returnValue = "";
      }
    };
    const handleUnload = () => {
      // removeMediumFromQueue();
      console.log("User chose to leave the page");
      // You can run any final cleanup code here before the page unloads
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);
  useEffect(() => {
    Pusher.logToConsole = true;
    var pusher = new Pusher("823ab72ed6ecd563b8c8", {
      cluster: "ap2",
    });

    var channel = pusher.subscribe("ondemand-booking");
    channel.bind("App\\Events\\OnDemandBooking", function (data) {
      console.log(data, "data");
      if (data.appointment !== undefined) {
        if (
          data.appointment.medium_id == id
        ) {
          // console.log(data, "data");
          setTime(0);
          setIsActive(false)
          setTimeLeft(0);
          setCounterStatus(true);
          setOndamandStatus(true);
          setMessage(data.message);
          setAppoinmentId(data.appointment.id);
          localStorage.setItem("appoinmentId", data.appointment.id);
          setmettingLink(data.appointment.meeting_link);
          localStorage.setItem("matched", "matched");
          if (
            data.appointment.meeting_link == null ||
            data.appointment.meeting_link == ""
          ) {
            getZoomLink(data.appointment.id);
          }
          console.log(data.appointment.meeting_link, "data.meeting_link");
          setloaderType("4");

          if (data.appointment.medium_id == id) {
            setshoHostStatus(true);
          } else {
            setshoHostStatus(false);
          }
        }
      }
    });
    // pusher.connection.bind('error', (err) => {
    //   console.error('Pusher error: ', err);
    //   // setError('An error occurred while connecting to Pusher');
    // });

    // Cleanup on component unmount
    // return () => {
    //   channel.unbind_all();
    //   pusher.unsubscribe('my-channel');
    // };
  }, []);
  // useEffect(() => {
  //   // if (loaderType == "4") {

  //   // }
  //   if (loaderType == "3") {

  //     // setTimeout(() => {
  //     //   removeMediumFromQueue()
  //     //   localStorage.setItem("ondemandLoader", "1")
  //     //   setloaderType('1');
  //     // }, 600000)
  //   }
  // }, [loaderType])
  const getZoomLink = (id) => {
    getZoomLinkOnDemand(id).then((data) => {
      console.log(data.data);
      if (data.data.success == "Appointment Zoom Link") {
        setmettingLink(data.data.data);
      }
    });
  };
  const hideCountdownTimer = () => {
    setCounterStatus(false);

    // window.open(meetingUrl, "_blank");
  };

  useEffect(() => {

    return () => {
      localStorage.removeItem("matched");
      removeMediumFromQueue(); // Call the API when the component unmounts
    };
  }, []);
  const fifteenMinutesBefore = (item) => {
    let status = true;
    let currenTime = moment().format("YYYY-MM-DD hh:mm A");

    let eventtime = moment(
      `${item.backupdate} ${item.time}`,
      "YYYY-MM-DD hh:mm A"
    ).format("YYYY-MM-DD hh:mm A");

    let beforeTime = moment(eventtime).subtract(15, "minutes");
    const differenceInMinutes = moment(eventtime).diff(currenTime, "minutes");
    if (differenceInMinutes > 0 && differenceInMinutes < 16) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };
  const backHandler = () => {

    window.loadModal2("#ForceBackHandler", "show");
    // dispatch(
    //   setLoadingContent(
    //     `<div>Please wait while we are removing you from queue ...</div>`
    //   )
    // );
    // dispatch(setLoadingText(true));
    // removeMediumFromQueue();
    // setTime(0);
    // setOndamandStatus(true);

    // setTimeout(() => {
    //   dispatch(setLoadingContent(""));
    //   dispatch(setLoadingText(false));
    //   let matched = localStorage.getItem("matched");
    //   if (!matched) {
    //     setloaderType("1");
    //   }
    // }, 5000);
  };

  const [timeLeft, setTimeLeft] = useState(''); // 10 minutes in seconds (600 seconds)
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {

    // if (activeEvent !== undefined) {


    // window.loadModal2("#TimeEndedPopup", "show");
    console.log(checkTimesEnd(), "checkTimesEnd()", isActive)
    if (timeLeft === 0 && isActive) {
      removeMediumFromQueue()
      // setloaderType("1");
      setIsActive(false);
      // navigate('/booking')
      window.loadModal2("#TimeEndedPopup", "show");
      return;
    }
    // If the time reaches zero, stop the timer.


    let intervalId;

    if (isActive && timeLeft > 0) {
      console.log(timeLeft, "setIsActive");
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId); // Clean up the interval on component unmount or if the timer stops
    // }

  }, [isActive, timeLeft]);

  // useEffect(() => {
  //   if (loaderType != "3") {
  //     setTimeLeft('');
  //     setIsActive(false);
  //   }
  // }, [loaderType]);

  const checkTimes = () => {
    let status = true;
    let currenTime = moment();

    let eventtime = currenTime.clone().add(runTime, 'minutes')

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime).diff(currenTime, "seconds");
    console.log(differenceInMinutes, "differenceInMinutes", "seconds");
    setTimeLeft(differenceInMinutes);
    setIsActive(true);
    // return differenceInMinutes;
  };
  const checkTimesEnd = () => {
    let status = true;
    let currenTime = moment();

    let eventtime = currenTime.clone().add(runTime, 'minutes')

    // let beforeTime = moment(eventtime).subtract(15, 'minutes');
    const differenceInMinutes = moment(eventtime).diff(currenTime, "seconds");
    return differenceInMinutes;

    // return differenceInMinutes;
  };
  return (
    <>
      {loaderType == "3" && (
        <>
          <div onClick={backHandler} className="back-btn-appointments pointer">
            <div className="bck">
              <img src="images/black-erow.png" alt="back" />
              <p className="margin-unset"> Back</p>
            </div>
          </div>
          <h4 className="text-center mb-3 mt-3">

            {activeEvent !== undefined && activeEvent.name}
          </h4>
          <h4 className="text-center  pl-5 pr-5">
            Hold on, we are searching for your match.
            <br /> Leaving this screen will stop your matching process.
          </h4>
          <div className="globe-gif-wrapper ">
            <img
              className="w-100 globe-image"
              // src={"/ondemand-icons/loader.png"}
              src={"/localImages/AroundTheWorld.gif"}
              alt="globe"
            />
          </div>
          {/* <video className="loader-video" autoPlay loop>
            <source src={"/localImages/AroundTheWorld.mp4"} type="video/mp4" />
          </video> */}

          <div className="d-flex align-items-center justify-content-around mt-3">

            <div
              onClick={backHandler}

              className="font-size-22 width-350-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center"
            >
              Exit Queue
            </div>


          </div>
        </>
      )}

      {loaderType == "2" && (
        <Questions
          startLoaderNow={startLoaderNow}
          onCancelQuestions={() => setloaderType("1")}
        />
      )}
      {loaderType == "4" && (
        <div className="ondemandpractice-wrapper margin-auto min-height-500px mt-5">
          <div className="">
            <div className="godemantevent-box-list">
              <p className="text-center mt-5 font-size-26">
                {" "}
                Your Appointment Is Confirmed!
              </p>

              {/* {shoHostStatus && (
                <h3 className="text-center mt-5 line-height-32px">
                  You are the Host,
                  <b className="text-underline">
                    {" "}
                    so be sure you are
                    <br /> logged into your Zoom
                  </b>{" "}
                  account to start
                  <br /> the session.
                </h3>
              )} */}
              {/* <p className="text-center mt-4 font-size-19">{message}</p> */}
              <p className="text-center mt-4 font-size-19">{'Please join within 5 minutes.'}</p>
              {counterStatus && (
                <CountdownTimerReact
                  hideCountdownTimer={hideCountdownTimer}
                  meeting_link={meeting_link}
                  getZoomLink={getZoomLink}
                />
              )}
              {/* <p className="text-center mt-3 font-size-19">
                You can either joining the session now, or go to your
                appointments
                <br /> page to find the zoom link to join the session.
              </p> */}
            </div>
            <br />
            {console.log(meeting_link, "meeting_link")}
            {(meeting_link == "" ||
              meeting_link == null ||
              meeting_link == undefined) && (
                <div className="text-center mb-3 mt-1">
                  <b> Zoom Link is being created</b>
                </div>
              )}
            <div className="d-flex align-items-center justify-content-center flex-column">
              {/* {console.log(meeting_link, "meeting_link")} */}
              {/* {meeting_link !== "" && ( */}
              <a
                href={
                  meeting_link !== "" &&
                    meeting_link !== null &&
                    meeting_link !== undefined
                    ? meeting_link
                    : "javascript:void();"
                }
                target={
                  meeting_link !== "" &&
                    meeting_link !== null &&
                    meeting_link !== undefined
                    ? "_blank"
                    : ""
                }
                // onClick={() => {

                //   setloaderType('1'); setTime(0);
                //   setOndamandStatus(false)
                // }}
                className={`${meeting_link !== "" &&
                  meeting_link !== null &&
                  meeting_link !== undefined
                  ? ""
                  : "disabled-btn-join"
                  } width-415-px  font-size-22 pointer mb-3 indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center`}
              >
                Join Zoom Session Here
              </a>
              {/* )} */}
              <div
                onClick={() => {
                  navigate(`/appointments?from=goondemand&appointment_id=${appoinmentId}`);
                  setloaderType("1");
                  setTime(0);
                  setOndamandStatus(false);
                }}
                className="width-415-px  pointer margin-unset  indexstyles__StyledButton-sc-83qv1q-0 jdbotF-blacnk sc-1qeub3n-4  d-flex align-items-center justify-content-center"
              >
                I Prefer to Find Zoom Link in Appointments Area
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-5 go-ondemand-practice-hover">
              <div className="col-md-4 hoverContent">
                <div className="hover-container d-flex justify-content-center ">
                  Having issues?{" "}
                  <p className="hover-target" tabindex="0">
                    <img src="/images/qu.png" className="ondemant-i-btn" />
                  </p>
                  <aside className="hover-popup-r">
                    <p className="mainpre">
                      Download the Zoom app to your device, then try connecting again.
                    </p>
                    <p className="mainpre">
                      Contact us at <a href="mailto:support@verysoul.com">
                        {" "}
                        support@verysoul.com
                      </a> for further assistance.
                    </p>

                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <ScheduledetailsModal
        info={info}
        setloaderType={setloaderType}
        Index={Index}
        checkMediumInOnDemand={checkMediumInOnDemand}
      /> */}
      {/* <ReminderPopUp activeEvent={activeEvent} /> */}
      {/* <AlredyJoined /> */}
      <ConfirmOut />
      <TimeEndedPopup setloaderType={setloaderType} addMediumQueue={addMediumQueue} />
      <ForceBackHandler setloaderType={setloaderType} />
    </>
  );
}
