import React, { useState } from 'react'

export default function BackFillCheck({ openBackFillPopup, closeBackFillPopup }) {
    const [error, setError] = useState(false);

    const [check4, setCheck4] = useState(false);
    const [check5, setCheck5] = useState(false);
    const [check6, setCheck6] = useState(false);
    const [checkYes, setCheckYes] = useState(false);
    // const [checkNo, setCheckNo] = useState(false);


    const onClickHandler2 = () => {


    };
    return (
        <div class="container-fluid question-container">
            <div class="row justify-content-center">
                <div class="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                    <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                        <form id="msform">
                            <fieldset>
                                <div class="form-card">
                                    <div class="">

                                        <h4 className='font-size-28 text-center'>Do you want us to check if there is a Medium<br />  available right now?</h4>


                                    </div>
                                </div>
                                <div className='backfill-ask'>
                                    <div className='ml-5'>
                                        <input
                                            type="button"
                                            name="next"
                                            class="next float-left action-button"
                                            value="Yes"
                                            onClick={openBackFillPopup}
                                        />
                                    </div>
                                    <div className='mr-5'>
                                        <input
                                            onClick={closeBackFillPopup}
                                            type="button"
                                            name="previous"
                                            class="previous float-right action-button-previous mr-5"
                                            value="No"
                                        />
                                    </div>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
