import React, { useEffect, useState } from "react";
import { Formik } from "formik";

import { useDispatch } from "react-redux";
import { updateInitialProfile } from "../../services/profile";
import { setLoading } from "../../reducer/auth/auth";
import Select from "react-select";

import {
  capatilizeLetter,
  filterVisibleTimeZones,
  filterZone,
  screenNameHandler,
} from "../../utils/helpers";
import PhoneInputField from "../../components/Inputfields/phoneinput";
import { createGuestBooking } from "../../services/client";
import Button from "../../components/button";
import { sendVerificationCode } from "../../services/auth";
import TickMark from "../../components/tickmark";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import PayOptionsPopUp from "./payment";
import WaitingTimerPopup from "../client/appointment/subcomponents/waitingTimer";

const stepOneData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  whatsapp: "",
  agree_to_legal_terms_and_conditionss: false,
  opt_in_out: false,
  paymenttype: "",
  over18: false,
  responsibility: false,
  permission: false,
  languages: [],
  country:""
};
const clientform = ({
  createBooking,
  bookingTime,
  bookingDate,
  timeZoneName,
  paymentInfo,
  session_id,
  subsession_id,
  medium_id,
  price,
  duration,
  setCounterStatus,
  counterStatus,
  formLanguages,
  countryData,
}) => {
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTitle, setVerifiedTitle] = useState("Send Verification Code");
  const [verifiedError, setVerifiedError] = useState(false);
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [resend, setResend] = useState(0);
  const [showResend, setshowResend] = useState(false);
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState(null)
  const [showVerificationstatus, setVerificationStatus] = useState(false);
  //
  const [userCountryCode, setUserCountryCode] = useState("US");
  const [newformData, setNewFormData] = useState([]);

  const [newCountryData, setNewCountryData] = useState([]);

  useEffect(() => {
    setNewFormData(
      formLanguages.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.name,
        };
      })
    );

    setNewCountryData(
      countryData.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.country_name,
        };
      })
    );

  }, ["", formLanguages, countryData]);

  const dispatch = useDispatch();

  const RunFunction = (formData) => {
    const {
      first_name,
      last_name,
      phone,
      email,
      agree_to_legal_terms_and_conditionss,
      opt_in_out,
      paymenttype,
      whatsapp,
      languages,
      country,
    } = formData;
    let lang = languages.map((item) => {
      return item.id;
    });
    let item = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      email: email,
      agree_to_legal_terms_and_conditionss: agree_to_legal_terms_and_conditionss,
      opt_in: opt_in_out === "Yes" ? true : false,
      paymenttype: paymenttype,
      whatsapp: whatsapp,
      country: country.label !== undefined ? country.label : country,
      languages: lang,
    };

    if (!verified && opt_in_out) {
      toast.error("Email Verification Required", { position: "top-center" });
      return;
    }

    createBooking(item);
    // createGuestBooking(item)
    //     .then((data) => {
    //         dispatch(setLoading(false));
    //         if (data.data.status) {

    //         }
    //     })
    //     .catch((err) => {
    //         dispatch(setLoading(false));
    //         console.log(err);
    //     });
  };

  const capatilizeLetters = (e, name, setFieldValue, uservalues) => {
    let values = e.target.value;
    const input = capatilizeLetter(values);
    setFieldValue(name, input);
  };

  //
  const handleChangeVerification = (e) => {
    setTime(0);
    setVerificationCode(e.target.value);
  };

  const sendVerificationCodeHandler = (values) => {
    console.log(values, "stepOneData");
    if (values.email === "") {
      toast.error("Please add email before verification", {
        position: "top-center",
      });
      return;
    }
    setTime(1);
    let body = {
      verification_code: verificationCode,
      email: values.email,
    };
    sendVerificationCode(body)
      .then((data) => {
        if (
          !data.data.status &&
          data.data.message === "OTP sent Successfully"
        ) {
          setVerificationStatus(true)
          let email = data.data.data.email;
          setTimeout(() => {
            setResend(1);
          }, 3000);
          setVerifiedTitle("Verify");
          setVerifiedMessage(`Verification Code sent successfully to ${email}`);
          setVerifiedError(false);


          //   setChangeEmailRequest(true);
        }
        if (!data.data.status && data.data.message === "Invalid OTP") {
          setVerifiedTitle("Verify");
          setVerifiedMessage("Invalid Verification Code");
          setVerifiedError(true);
          //   setChangeEmailRequest(false);
        }
        if (data.data.status && data.data.message === "Verified") {
          setVerifiedTitle("Verified");
          setVerifiedMessage(data.data.message);
          setVerifiedError(false);
          setVerified(true);
          setResend(0);
          //   setChangeEmailRequest(false);
        }
      })
      .catch((data) => {
        setVerifiedMessage("Please Try Again");
        setVerifiedError(true);
      });
  };
  const reSendVerificationCodeHandler = (values) => {
    sendVerificationCodeHandler(values);
    setResend(2);
    setTimeout(() => {
      setResend(0);
    }, 4000);
  };

  const checkPyamentType = (info, type) => {
    let status = false;

    if (type === "paypal") {
      if (info !== undefined && info.is_paypal_integrated) {
        status = true;
      } else {
        status = false;
      }
    }

    if (type === "stripe") {
      if (info !== undefined && info.is_stripe_integrated) {
        status = true;
      } else {
        status = false;
      }
    }

    if (type === "squareup") {
      if (info !== undefined && info.is_squareup_integrated) {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  const customhandleChangeEmail = (e, setFieldValue) => {
    console.log(e.target.value);
    setFieldValue('email', e.target.value);
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      let values = {
        email: e.target.value,
      }
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {

        sendVerificationCodeHandler(values)
      }

    }, 3000)

    setTimer(newTimer)
  }
  const countryChangeHandler = (value, name, setFieldValue) => {
    setUserCountryCode(value.country_code);
    setFieldValue(name, value);
  };
  return (
    <>
      <Formik
        initialValues={stepOneData}
        validate={(values) => {
          const errors = {};
          if (!values.first_name) errors.first_name = "Required";
          if (!values.last_name) errors.last_name = "Required";
          if (!values.phone) errors.phone = "Required";
          if (values.phone && values.phone.length < 10) {
            errors.phone = "Invalid Phone";
          }
          if (values.whatsapp && values.whatsapp.length < 10) {
            errors.whatsapp = "Invalid Phone";
          }
          
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.agree_to_legal_terms_and_conditionss)
            errors.agree_to_legal_terms_and_conditionss = "Required";

          if (!values.opt_in_out) errors.opt_in_out = "Required";
          if (!values.over18) errors.over18 = "Required";
          if (!values.responsibility) errors.responsibility = "Required";
          if (!values.permission) errors.permission = "Required";

          if (!values.languages.length) errors.languages = "Required";
          if (!values.country) errors.country = "Required";
          if (parseInt(session_id) !== 7) {
            if (!values.paymenttype) {
              errors.paymenttype = "Required";
            }
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          RunFunction(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form-width-guest-client mt-5 mb-5"
          >
            {/* {checkTat()} */}
            {/* <h2 className="text-center mb-2">Please Confirm To Continue</h2> */}
            <span className="d-block text-center m-2 advance-guest-book-p">
              <b>Timezone : </b>
              {timeZoneName}
            </span>
            <span className="d-block text-center m-2 advance-guest-book-p">
              <b>Booking Date : </b>
              {bookingDate}
            </span>
            <span className="d-block text-center m-2 advance-guest-book-p">
              <b>Booking Time : </b>
              {bookingTime}
            </span>

            <div className="from-inner-box mt-5">
              <div className="left-side">
                <label>
                  First Name<i>*</i>
                </label>
                <input
                  onChange={(e) =>
                    capatilizeLetters(e, "first_name", setFieldValue, values)
                  }
                  className={` margin-unset ${errors.first_name && touched.first_name && errors.first_name
                    ? "error-boundry"
                    : ""
                    }`}
                  onBlur={handleBlur}
                  value={values.first_name}
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                />
                <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                  {errors.first_name && touched.first_name && errors.first_name}
                </span>
              </div>
              <div className="right-side">
                <label>
                  Last Name<i>*</i>
                </label>
                <input
                  className={` margin-unset  ${errors.last_name && touched.last_name && errors.last_name
                    ? "error-boundry"
                    : ""
                    }`}
                  onChange={(e) =>
                    capatilizeLetters(e, "last_name", setFieldValue, values)
                  }
                  onBlur={handleBlur}
                  value={values.last_name}
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                />
                <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                  {errors.last_name && touched.last_name && errors.last_name}
                </span>
              </div>
            </div>
            <div className="from-inner-box">
              {/* <div className="left-side"> */}
              <label>
                Email<i>*</i>
              </label>
              <input
                className={`  margin-unset  ${errors.email && touched.email && errors.email
                  ? "error-boundry"
                  : ""
                  }`}
                onChange={(e) => customhandleChangeEmail(e, setFieldValue)}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                id="email"
                name="email"
                placeholder="myemail@address.com"
              />
              <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                {errors.email && touched.email && errors.email}
              </span>
              <div
                className={`text-left mt-1 ${verifiedError ? "red" : "green"
                  }`}
              >
                {!verifiedError && verifiedTitle === "Verify" ? verifiedMessage : ''}
                {/* </div> */}
              </div>{" "}

            </div>
            <div className="from-inner-box clear-both ">
              <div className="left-side ">
                <div className="guest-booking-country-language">
                  <label>
                    Country <i>*</i>
                  </label>
                  <Select
                    id="country"
                    className="selection-box"
                    options={newCountryData}
                    defaultInputValue={
                      typeof values.country == "object"
                        ? values.country.country_name
                        : values.country
                    }
                    onChange={(value) =>
                      countryChangeHandler(value, "country", setFieldValue)
                    }
                    onBlur={handleBlur}
                    placeholder="Country*"
                    value={values.country}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={true}
                  />
                  <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                    {errors.country && touched.country && errors.country}
                  </span>
                </div>
              </div>
              <div className="right-side ">
                <div className="guest-booking-country-language">
                  <label>
                    Languages<i>*</i>
                  </label>
                  <Select
                    id="languages"
                    className="selection-box"
                    options={newformData}
                    multi={true}
                    isMulti
                    onChange={(value) => setFieldValue("languages", value)}
                    onBlur={handleBlur}
                    placeholder="Select languages"
                    value={values.languages}
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={false}
                  />
                  <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                    {errors.languages && touched.languages && errors.languages}
                  </span>
                </div>
              </div>
            </div>
            <div className="from-inner-box clear-both">
              <div className="left-side mt-4">
                <label>
                  Phone<i>*</i>

                </label>
                <PhoneInputField
                  className={` margin-unset number-input ${errors.phone && touched.phone && errors.phone
                    ? "error-boundry"
                    : ""
                    }`}
                  country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.phone}
                  id="phone"
                  name="phone"
                /><span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                {errors.phone && touched.phone && errors.phone}
              </span>

              </div>
              <div className="right-side mt-4">
                <label>
                  Whatsapp

                </label>
                <PhoneInputField
                  className={` margin-unset number-input ${errors.whatsapp && touched.whatsapp && errors.whatsapp
                    ? "error-boundry"
                    : ""
                    }`}
                  country={userCountryCode.toLowerCase()}
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  value={values.whatsapp}
                  id="whatsapp"
                  name="whatsapp"
                />

              </div>


            </div>

            {showVerificationstatus ?
              <div className="from-inner-box ">
                <div className="from-inner-box">
                  <div className="verification-code-section">
                    <label className="mt-4">
                      Email Verification Code<i>*</i>
                    </label>
                    <div className="d-flex">
                      {verifiedTitle !== "Send Verification Code" && (
                        <input
                          onChange={handleChangeVerification}
                          onBlur={handleBlur}
                          className="height-45 margin-right-30"
                          value={verificationCode}
                          type="text"
                          id="codeverification"
                          name="codeverification"
                          placeholder="Code"
                        />
                      )}

                      <Button
                        status={0}
                        type="button"
                        className={`resend-btn w-unset height-45 min-width-130px ${verifiedTitle === "Verify" ? "bg-green" : ""
                          }`}
                        onClick={
                          time === 0
                            ? () => sendVerificationCodeHandler(values)
                            : () => { }
                        }
                        title={verifiedTitle}
                      />
                      {(verifiedMessage !== "Verified" || showResend) && (
                        <>
                          {resend === 1 && (
                            <Button
                              status={0}
                              type="button"
                              className={`resend-btn w-unset height-45 min-width-130px ml-2`}
                              onClick={() =>
                                reSendVerificationCodeHandler(values)
                              }
                              title={"Resend"}
                            />
                          )}
                          {resend === 2 && <TickMark />}
                        </>
                      )}
                    </div>
                    {verifiedTitle === "Verify" && (
                      <>
                        {" "}
                        <div className="text-left font-size-11">
                          {" "}
                          <i>*</i>Delivery may take up to 10 minutes.
                        </div>
                        <div className="red text-left mt-1">
                          <i>*</i>Please expect significant mail delivery delays
                          if using Yahoo Mail.
                        </div>
                      </>
                    )}
                    {verifiedTitle === "Verified" && (
                      <div className="text-left font-size-11">
                        {" "}
                        <i>*</i>Email Succesfully Verfied
                      </div>
                    )}
                    <div
                      className={`text-left mt-1 ${verifiedError ? "red" : "green"
                        }`}
                    >
                      {verifiedError && verifiedTitle === "Verify" || !verifiedError && verifiedTitle === "Verified" ? verifiedMessage : ''}
                    </div>
                  </div>

                </div>
              </div>
              :
              <div className="from-inner-box ">
                <div className="from-inner-box">
                  <div className="verification-code-section">
                    <label className="mt-4">
                      Email Verification Code<i>*</i>
                    </label>
                    <div className="d-flex">

                      <input
                        // onChange={handleChangeVerification}
                        // onBlur={handleBlur}
                        disabled={true}
                        className="height-45 margin-right-30"
                        value={verificationCode}
                        type="text"
                        id="codeverification"
                        name="codeverification"
                        placeholder="Code"
                      />




                    </div>
                    <div className="text-left font-size-11">
                      {" "}
                      <i>*</i>Type email address above to get code
                    </div>



                  </div>

                </div>
              </div>}
            <div className="from-inner-box radeo check-box-input payment-saction mt-4">
              <div className="full-fild">
                <label>
                  Opt-In? <i>*</i>{" "}
                  <span className="font-12 red mb-3 height-10 mt-1">
                    {errors.opt_in_out &&
                      touched.opt_in_out &&
                      errors.opt_in_out}
                  </span>
                </label>
                <h4>
                  I would like to receive notices of offers (for free events,
                  opportunities, etc.)
                </h4>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="Yes"
                    id="opt_in"
                    type="radio"
                    name="opt_in_out"
                    checked={values.opt_in_out === "Yes" ? true : false}
                  />
                  <label htmlFor="optin">Yes</label>
                </div>
                <div className="check-field">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="No"
                    id="opt_out"
                    type="radio"
                    name="opt_in_out"
                    checked={values.opt_in_out === "No" ? true : false}
                  />
                  <label htmlFor="optout">No</label>
                </div>
              </div>
            </div>

            <div className="from-inner-box mt-4">
              <div className="full-fild">
                <label>
                  Legal and Privacy <i>*</i>{" "}
                  <span className="font-12 red mb-3 height-10 mt-1 ">
                    {errors.agree_to_legal_terms_and_conditionss &&
                      touched.agree_to_legal_terms_and_conditionss &&
                      errors.agree_to_legal_terms_and_conditionss}
                  </span>
                </label>

                <div className="prvacy-box">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="I have read and agree to the terms and conditions"
                    id="termsandconditions"
                    type="checkbox"
                    name="agree_to_legal_terms_and_conditionss"
                    defaultChecked={values.agree_to_legal_terms_and_conditionss}
                  />
                  <label htmlFor="termsandconditions" className="width-unset">
                    I have read and agree to the terms and conditions.
                  </label>

                  <div className="tr-pr">
                    <Link to="/terms-conditions" target="_blank">
                      Terms &amp; Conditions
                    </Link>
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/************ */}
            <div className="from-inner-box mt-4">
              <div className="full-fild">
                {/* <label>
                   I am over 18 <i>*</i>{" "}
                  <span className="font-12 red mb-3 height-10 mt-1 ">
                    {errors.agree_to_legal_terms_and_conditionss &&
                      touched.agree_to_legal_terms_and_conditionss &&
                      errors.agree_to_legal_terms_and_conditionss}
                  </span>
                </label> */}

                <div className="prvacy-box">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="over18"
                    id="over18"
                    type="checkbox"
                    name="over18"
                    defaultChecked={values.over18}
                  />
                  <label htmlFor="over18">
                    I am over 18
                  </label>


                </div>
                <span className="font-12 red mb-3 height-10 mt-1 ">
                  {errors.over18 &&
                    touched.over18 &&
                    errors.over18}
                </span>
              </div>
            </div>
            <div className="from-inner-box mt-4">
              <div className="full-fild">
                {/* <label>
                   I am over 18 <i>*</i>{" "}
                  <span className="font-12 red mb-3 height-10 mt-1 ">
                    {errors.agree_to_legal_terms_and_conditionss &&
                      touched.agree_to_legal_terms_and_conditionss &&
                      errors.agree_to_legal_terms_and_conditionss}
                  </span>
                </label> */}

                <div className="prvacy-box">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="responsibility"
                    id="responsibility"
                    type="checkbox"
                    name="responsibility"
                    defaultChecked={values.responsibility}
                  />
                  <label htmlFor="responsibility">
                    I take sole responsibility for my interpretations, choices, and actions.
                  </label>

                </div>
                <span className="font-12 red mb-3 height-10 mt-1 ">
                  {errors.responsibility &&
                    touched.responsibility &&
                    errors.responsibility}
                </span>
              </div>
            </div>
            <div className="from-inner-box mt-4">
              <div className="full-fild">
                {/* <label>
                   I am over 18 <i>*</i>{" "}
                  <span className="font-12 red mb-3 height-10 mt-1 ">
                    {errors.agree_to_legal_terms_and_conditionss &&
                      touched.agree_to_legal_terms_and_conditionss &&
                      errors.agree_to_legal_terms_and_conditionss}
                  </span>
                </label> */}

                <div className="prvacy-box">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="permission"
                    id="permission"
                    type="checkbox"
                    name="permission"
                    defaultChecked={values.permission}
                  />
                  <label htmlFor="permission">
                    I will not record without permission from the Medium and understand any &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; recordings are for private use only.
                  </label>


                </div>
                <span className="font-12 red mb-3 height-10 mt-1 ">
                  {errors.permission &&
                    touched.permission &&
                    errors.permission}
                </span>
              </div>
            </div>
            {/*********** */}

            {parseInt(session_id) !== 7 && (
              <div className="row-one-step p-2">
                <div className="full-side">
                  <label>Payment Methods</label>

                  {/* stepOneData.mediumship_level */}
                  <div className="full-fild">
                    <>
                      {checkPyamentType(paymentInfo, "paypal") && (
                        <div className="payment-option">
                        <div className="check-field">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="radio"
                            name="paymenttype"
                            id="payment-type-paypal"
                            value={"paypal"}
                            checked={values.paymenttype === "paypal" ? true : false}
                          />
                          <label
                            className="w-unset"
                            htmlFor="payment-type-paypal"
                          >
                            Paypal
                          </label>
                        </div>
                        <div className="payment-subtext">
                          (PayPal or credit/debit card)
                        </div>
                      </div>
                      )}
                      {checkPyamentType(paymentInfo, "stripe") && (
                        <div className="payment-option">
                        <div className="check-field">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="radio"
                            name="paymenttype"
                            id="payment-type-stripe"
                            value={"stripe"}
                            checked={
                              values.paymenttype === "stripe" ? true : false
                            }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                          />
                          <label
                            className=" w-unset"
                            htmlFor="payment-type-stripe"
                          >
                            Stripe
                          </label>
                          </div>
                          <div className="payment-subtext">
                          (credit/debit card)
                        </div>
                        </div>
                        
                      )}
                      {checkPyamentType(paymentInfo, "squareup") && (
                        <div className="payment-option">
                        <div className="check-field">
                          <input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="radio"
                            name="paymenttype"
                            id="payment-type-square"
                            value={"square"}
                            checked={
                              values.paymenttype === "square" ? true : false
                            }
                          // checked={
                          //   parseInt(values.when_do_i_pay) === 1 ? true : false
                          // }
                          />
                          <label
                            className=" w-unset"
                            htmlFor="payment-type-square"
                          >
                            Square Up
                          </label>
                          </div>
                          <div className="payment-subtext">
                          (credit/debit card)
                        </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            )}
            <div className="d-block">
              <span className="font-size-12 font-12 red mb-3 height-10 mt-1 ">
                {errors.paymenttype &&
                  touched.paymenttype &&
                  errors.paymenttype}
              </span>
            </div>

            <div className="bution">
              <button
                // onClick={() => createBooking(values)}
                type="submit"
                className="button-bx"
              // value="Next"
              >
                Continue
              </button>
            </div>
          </form>
        )}
      </Formik>
      {/* <PayOptionsPopUp paymentInfo={paymentInfo}
                session_id={session_id}
                subsession_id={subsession_id}
                medium_id={medium_id}
                price={price}
                duration={duration}
                bookingTime={bookingTime}
                bookingDate={bookingDate}
                setCounterStatus={setCounterStatus}
            /> */}
      {counterStatus && <WaitingTimerPopup />}
    </>
  );
};
export default clientform;
