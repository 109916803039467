import React, { useEffect, useState } from "react";
import "./backfill.css";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
    getMediumBackfillSettings,
    saveMediumBackfillSettings,
} from "../../../services/medium";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeQueryParams, setQueryParams } from "../../../utils/helpers";
import { setPathArray } from "../../../reducer/adminSlice";
import ToggleButton from "../../medium/backfill/components/toggle/toggle";

export default function BackFillAdmin() {
    const { id } = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const [service_type, setservice_type] = useState(["donated"]);
    const [is_automatic, setis_automatic] = useState(false);
    const [notification_method, setnotification_method] = useState("");
    const [appointment_time, setappointment_time] = useState("");
    const [user_id, setuser_id] = useState("");
    const [mediumId, setMediumId] = useState("");

    const [min_notice, setmin_notice] = useState("0.15");
    const [unitValue, setUnitValue] = useState('Minutes')
    const [isOn, setIsOn] = useState(true);

    const navigate = useNavigate();
    //query params
    const [searchParams, setSearchParams] = useSearchParams();
    const status = searchParams.get("view");
    const searchVal = searchParams.get("search");
    const searchName = searchParams.get("searchName");
    const searchOprator = searchParams.get("searchOprator");
    const searchFilterOprater = searchParams.get("searchFilterOprater");
    const page = searchParams.get('page');
    const rowperpage = searchParams.get('rowperpage');
    //query state
    const [queryValue, setQueryValue] = useState('');
    const [query1, setQuery1] = useState('');
    const [query2, setQuery2] = useState('');
    const [query3, setQuery3] = useState('');
    const [query4, setQuery4] = useState('');
    const [query5, setQuery5] = useState('');
    const [query6, setQuery6] = useState('');
    const params = useParams();
    useEffect(() => {
        localStorage.setItem('pagintionTYPE', 'change')
        dispatch(setPathArray(params.id));
        setQueryParams(setQueryValue, setQuery1, setQuery2, setQuery3, setQuery4, setQuery5, setQuery6, status, searchVal, searchName, page, rowperpage, searchOprator, searchFilterOprater)
        setTimeout(() => {
            removeQueryParams()
        }, 2000);
        return () => {
            dispatch(setPathArray(null));
        };

    }, []);
    useEffect(() => {
        const urlPath = window.location.pathname;
        const lastSegment = urlPath.split('/').pop();
        if (!isNaN(lastSegment)) {
            getData(lastSegment);
            setMediumId(lastSegment)
        } else {
            getData(id);
            setMediumId(id)
        }
    }, []);

    const getData = (ID) => {
        getMediumBackfillSettings(ID).then((data) => {
            if (data.data.success == "Request completed successfully") {
                if (data.data.data) {
                    let info = data.data.data;
                    console.log(id, "info anuj");
                    setservice_type(info.service_type);
                    setis_automatic(info.is_automatic == 0 ? "false" : "true");
                    setnotification_method(info.notification_method);
                    setappointment_time(info.appointment_time);
                    setuser_id(info.id);
                    setmin_notice(info.min_notice);
                    setIsOn(info.status == 0 ? true : false)
                    let array = ["0.15", "0.30", "0.45"]
                    if (array.includes(info.min_notice)) {
                        setUnitValue("Minutes")
                    }
                    else {
                        setUnitValue("Hours")
                    }
                }
            }
        });
    };
    const handleSubmit = (type, on) => {
        let body = {
            medium_id: mediumId,
            service_type: service_type,
            is_automatic: is_automatic == "true" ? 1 : 0,
            notification_method: notification_method,
            appointment_time: appointment_time,
            min_notice: min_notice,
        };
        if (type == "no") {
            Object.assign(body, { status: !on ? 1 : 0 });
        }
        else {
            Object.assign(body, { status: !isOn ? 1 : 0 });
        }
        if (user_id !== "") {
            Object.assign(body, { id: user_id });
        }
        saveMediumBackfillSettings(body)
            .then((data) => {
                console.log(data.data, "data.data");
                if (data.data.success) {
                    setuser_id(data.data.data.id);
                    if (type !== "no") {

                        toast.success("Settings updated succesfully", {
                            position: "top-center",
                        });
                    }
                } else {
                    toast.error("Something went wrong", {
                        position: "top-center",
                    });
                }
            })
            .catch(() => {
                toast.error("Something went wrong", {
                    position: "top-center",
                });
            });
    };

    const serviceTypeHandler = (e) => {
        let checked = e.target.checked;
        let value = e.target.value;

        let array = [...service_type];
        if (checked) {
            array.push(value);
            setservice_type(array);
        } else {
            let newArray = array.filter((e) => e !== value);
            setservice_type(newArray);
        }
    };

    const changeUnitValue = (e) => {
        let value = e.target.value
        if (value == "Hours") {
            setmin_notice("1")
        }
        if (value == 'Minutes') {
            setmin_notice("0.15")
        }
        setUnitValue(value)
    }

    const handleClick = () => {
        // if (!isOn) {
        handleSubmit('no', !isOn)
        // }
        setIsOn((prevState) => !prevState);
    };
    return (

        <div className="backfill-wrapper">
            <div className="back-btn-profile">
                <span className="back-handler pointer" onClick={() => status === null ? navigate(-1) : navigate(`/mediums?view=${queryValue}&search=${query1}&searchName=${query2}&page=${query3}&rowperpage=${query4}&pagintion=${'change'}&searchOprator=${query5}&searchFilterOprater=${query6}`)} >
                    <img src="../images/arrow-back.png" alt="go back" />
                    Go Back
                </span>
                <div className="d-flex align-items-center mt-4">

                    <h3 className="margin-left-60px margin-bottom-unset">Backfill Settings</h3>
                    <ToggleButton isOn={isOn} setIsOn={setIsOn} handleClick={handleClick} from={'admin'} />
                </div>
            </div>
            {console.log(service_type, "setservice_type")}
            <div className="backfill-container">
                <div className="backfill-left-side ">
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Automatically Apply Settings</h4>{" "}
                        <div>
                            {console.log(is_automatic, "is_automatic")}
                            <div class="select-dropdown">
                                <select
                                    value={is_automatic}
                                    onChange={(e) => setis_automatic(e.target.value)}
                                    className="height-35 "
                                    name={`is_automatic`}
                                >

                                    <option value={"true"}> Yes</option>
                                    <option value={"false"}>No, Please allow me to choose for each cancellation*</option>
                                </select>
                            </div>
                            {/* <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setis_automatic(e.target.value)}
                                    checked={is_automatic == "true"}
                                    value={"true"}
                                    id="AutomaticYes"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticYes" className="width-unset">
                                    Yes
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // defaultChecked={true}
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setis_automatic(e.target.value)}
                                    checked={is_automatic == "false"}
                                    value={"false"}
                                    id="AutomaticNo"
                                    type="radio"
                                    name="Automatic"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="AutomaticNo" className="width-unset">
                                    No, Please allow me to choose for each cancellation<i>*</i>
                                </label>
                            </div> */}
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Triggering Services</h4>{" "}
                        <div className="d-flex gap-10">
                            <div className="prvacy-box">
                                {console.log(service_type, "service_type")}
                                <input
                                    // defaultChecked={true}
                                    onChange={(e) => serviceTypeHandler(e)}
                                    // onBlur={handleBlur}
                                    defaultChecked={
                                        service_type.includes("donated") ? true : false
                                    }
                                    checked={service_type.includes("donated") ? true : false}
                                    value="donated"
                                    id="donated"
                                    type="checkbox"
                                    name="donated"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="donated" className="width-unset">
                                    Donated<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => serviceTypeHandler(e)}
                                    defaultChecked={service_type.includes("paid") ? true : false}
                                    checked={service_type.includes("paid") ? true : false}
                                    value="paid"
                                    id="paid"
                                    type="checkbox"
                                    name="paid"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="paid" className="width-unset">
                                    Paid{" "}
                                    <span className="i-button-backfill tooltip-1-focus-backfill"></span>
                                    <span className="tooltip-1-backfill">
                                        Would result in backfill donated session
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Appointment Times </h4>{" "}
                        <div>
                            <div class="select-dropdown">
                                <select
                                    value={appointment_time}
                                    onChange={(e) => setappointment_time(e.target.value)}
                                    className="height-35 "
                                    name={`appointment_time`}
                                >

                                    <option value={"exact"}> Exact match only</option>
                                    <option value={"any"}> Any that fit donated schedule<i>*</i></option>
                                    {/* <option value={"1"}>60 Minutes</option> */}
                                </select>
                            </div>
                            {/* <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setappointment_time(e.target.value)}
                                    checked={appointment_time == "exact"}
                                    value="exact"
                                    id="Exact-match-only"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Exact-match-only" className="width-unset">
                                    Exact match only{" "}
                                    <span className="i-button-backfill tooltip-2-focus-backfill"></span>
                                    <span className="tooltip-2-backfill">
                                        Relates to canceled session
                                    </span>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setappointment_time(e.target.value)}
                                    checked={appointment_time == "any"}
                                    value="any"
                                    id="Any-that-fit-donated-schedule"
                                    type="radio"
                                    name="Appointment-Times"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label
                                    htmlFor="Any-that-fit-donated-schedule"
                                    className="width-unset"
                                >
                                    Any that fit donated schedule<i>*</i>
                                </label>
                            </div> */}
                        </div>
                    </div>

                    <div className="d-flex margin-60px flex-wrap ">
                        <h4 className="width-260-px">Minimum Notice</h4>{" "}
                        <div>
                            <div className="prvacy-box d-flex align-items-center justify-content-between">
                                <div className="minimum-inner-row-left">
                                    {" "}
                                    <span>Unit</span>
                                    <div class="select-dropdown">
                                        <select

                                            className="height-35 w-100px"
                                            value={unitValue}
                                            name="minunitchange"
                                            onChange={(e) => changeUnitValue(e)}
                                        >
                                            <option value="Minutes">Minutes</option>
                                            <option value="Hours">Hours</option>
                                        </select>
                                    </div>
                                </div>
                                <>
                                    {unitValue === 'Hours' ?
                                        <div className="minimum-inner-row-left">
                                            {" "}
                                            <span>Hours</span>
                                            <div class="select-dropdown">
                                                <select

                                                    value={min_notice}
                                                    onChange={(e) => setmin_notice(e.target.value)}
                                                    className="height-35 w-100px"
                                                    name={`buffer_time`}
                                                >
                                                    <option disabled selected value="">
                                                        Hours
                                                    </option>
                                                    <option value={"1"}>1 Hour</option>
                                                    <option value={"2"}>2 Hour</option>
                                                    <option value={"3"}>3 Hour</option>
                                                    <option value={"4"}>4 Hour</option>
                                                    <option value={"5"}>5 Hour</option>
                                                    <option value={"6"}>6 Hour</option>
                                                </select>
                                            </div>

                                        </div>

                                        :
                                        <div className="minimum-inner-row-left">
                                            {" "}
                                            <span>Min</span>
                                            <div class="select-dropdown">
                                                <select

                                                    value={min_notice}
                                                    onChange={(e) => setmin_notice(e.target.value)}
                                                    className="height-35 w-100px"
                                                    name={`buffer_time`}
                                                >
                                                    <option disabled selected value="">
                                                        Minutes
                                                    </option>
                                                    <option value={"0.15"}>15 Minutes</option>
                                                    <option value={"0.30"}>30 Minutes</option>
                                                    <option value={"0.45"}>45 Minutes</option>
                                                    {/* <option value={"1"}>60 Minutes</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="backfill-right-side ">

                    <div className="d-flex margin-left-60px flex-wrap ">
                        <h4 className="width-260-px">Notification Method</h4>{" "}
                        <div>
                            <div class="select-dropdown">
                                <select
                                    value={notification_method}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    className="height-35 "
                                    name={`notification_method`}
                                >

                                    <option value={"email"}>Email Only</option>
                                    <option value={"email_sms"}> Email & Text (U.S. & CAN only)<i>*</i></option>
                                    <option value={"email_whatsapp"}> Email & WhatsApp (all countries)<i>*</i></option>
                                    {/* <option value={"1"}>60 Minutes</option> */}
                                </select>
                            </div>
                            {/* <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email"}
                                    value="email"
                                    id="Email-Only-1"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-1" className="width-unset">
                                    Email Only
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email_sms"}
                                    value="email_sms"
                                    id="Email-Only-2"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-2" className="width-unset">
                                    Email & Text (U.S. & CAN only)<i>*</i>
                                </label>
                            </div>
                            <div className="prvacy-box">
                                <input
                                    // onChange={handleChange}
                                    // onBlur={handleBlur}
                                    onChange={(e) => setnotification_method(e.target.value)}
                                    checked={notification_method == "email_whatsapp"}
                                    value="email_whatsapp"
                                    id="Email-Only-3"
                                    type="radio"
                                    name="Email-Only"
                                // disabled
                                // checked={values.agree_to_legal_terms_and_conditionss ? 'checked' : ''}
                                />

                                <label htmlFor="Email-Only-3" className="width-unset">
                                    Email & WhatsApp (all countries)<i>*</i>
                                </label>
                            </div> */}
                        </div>

                    </div>
                    <div className="d-flex margin-60px mt-5 flex-wrap ">
                        <span className="red">Back-filled appointments will trigger messages to your selected contact source(s) listed above.</span>
                    </div>
                </div>
            </div>

            <div className="d-flex margin-60px mt-2 flex-wrap ">
                <span className="red">*Defaulted or Suggested settings</span>
            </div>
            <div className="d-flex align-items-center justify-content-center mt-5">
                <div
                    onClick={handleSubmit}
                    className={`font-size-22 width-350-px white-border pointer indexstyles__StyledButton-sc-83qv1q-0 jdbotF sc-1qeub3n-4  d-flex align-items-center justify-content-center`}
                >
                    Submit
                </div>
            </div>
        </div>
    );
}
